exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-comment-js": () => import("./../../../src/pages/article/Comment.js" /* webpackChunkName: "component---src-pages-article-comment-js" */),
  "component---src-pages-article-strapi-post-slug-js": () => import("./../../../src/pages/article/{StrapiPost.slug}.js" /* webpackChunkName: "component---src-pages-article-strapi-post-slug-js" */),
  "component---src-pages-category-strapi-category-slug-js": () => import("./../../../src/pages/category/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-category-strapi-category-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */)
}

